<template>
  <div id="policy-container">
    <div class="title">Yerelleştirme</div>

    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }"><i class="icon-home"></i></el-breadcrumb-item>
        <el-breadcrumb-item>Ana Sayfa</el-breadcrumb-item>
        <el-breadcrumb-item>Yerelleştirme</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-row :gutter="24">
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Yerelleştirme</span>
          </div>
          <div class="text">Yerelleştirme Anahtarlarını indirmek için "Anahtarları İndir" butonuna basınız.</div>
          <el-button class="export-btn" @click="exportKeys()">Anahtarları İndir</el-button>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>Yerelleştirme</span>
          </div>
          <div class="text">Yerelleştirme Anahtarlarını yüklemek için "Anahtarları Yükle" butonuna basınız.</div>
          <el-upload class="el-up" :action="$client.defaults.baseURL + 'Localization/Upload'" :headers="{
            Authorization: $client.defaults.headers.common['Authorization'],
          }" :show-file-list="false" :on-success="fileSuccessKey">
            <el-button type="primary"> Anahtarları Yükle</el-button>
          </el-upload>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
export default {
  data() {
    return {
      onSave: false,
    };
  },

  async beforeMount() { },

  computed: {
    getEnums() {
      return this.$store.getters.getEnums;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    exportKeys() {
      var label = "DilAnahtarları.xlsx";
      this.$client
        .get("/Localization/Export", { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    fileSuccessKey() {
      this.$message.success("Dosya başarıyla yüklendi");
    },
  },
};
</script>

<style lang="less">
#policy-container {
  padding: 42px 30px;

  .title {
    font-size: 30px;
    color: #44566c;
    font-weight: 700;
    line-height: 35px;
    padding-bottom: 22px;

    span {
      color: #8697a8;
    }
  }

  .breadcrumb {
    .buttons-group {
      display: flex;
      gap: 24px;
      align-items: center;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        border: none;
        border-radius: 50%;
      }

      .delete {
        background: #eaedf0;

        i {
          background: #44566c;
        }
      }

      .add {
        box-shadow: 0px 8px 16px rgba(0, 129, 255, 0.2);
        background: #54d0c8;

        i {
          background: white;
        }
      }
    }
  }

  .box-card {
    .text {
      font-size: 14px;
      color: #8697a8;
      line-height: 20px;
      padding-bottom: 22px;
    }

    .export-btn {
      border: none;
      background: #28ace1;
      color: white;
    }
  }
}
</style>
